import Auth from "cp-client-auth!sofe";
import { first } from "rxjs/operators";
import { handleError } from "../handle-error";
import { fetcher, onPusher } from "fetcher!sofe";

// Handles redirecting users to an in progress page if a crm migration is in progress
// Also handles showing the migration complete and error modals
export default async function crmMigrationBootstrap() {
  const ignoredRoutes = [
    "#/crm-migration-in-progress",
    "#/logout",
    "#/select-user",
  ];

  function redirectToInProgress() {
    if (!ignoredRoutes.includes(window.location.hash)) {
      window.location.hash = "#/crm-migration-in-progress";
    }
  }

  function addRoutingListener() {
    window.addEventListener("single-spa:before-routing-event", redirectToInProgress);
  }
  function removeRoutingListener() {
    window.removeEventListener("single-spa:before-routing-event", redirectToInProgress);
  }

  function subscribeToPusher(tenantId) {
    return onPusher(
      "crm_migration_status_change",
      `private-tenant-${tenantId}`
    ).subscribe(async () => {
      try {
        const migration = await fetcher("/api/v2/migration");
        if (migration && (migration.status === "running" || migration.status === "awaiting")) {
          redirectToInProgress();
          addRoutingListener();
        } else if (["error", "done", "cancelled"].includes(migration?.status)) {
          removeRoutingListener();
          window.location.hash = "#/home/overview";
          window.location.reload();
        }
      } catch (error) {
        handleError(error);
      }
    }, handleError);
  }

  try {
    const user = await Auth.getLoggedInUserAsObservable()
      .pipe(first())
      .toPromise();
    const tenant = await Auth.getTenantAsObservable().pipe(first()).toPromise();

    const hasBeta = await Auth.hasBetaObs("crm_migration")
      .pipe(first())
      .toPromise();
    if (!hasBeta) { return }

    if (tenant.crm_status === "migration_started") {
      redirectToInProgress();
      addRoutingListener();
    } else if (tenant.crm_status === "crm_old" || tenant.crm_status === "awaiting_migration" || tenant.crm_status === "error") {
      subscribeToPusher(tenant.id);
      const migration = await fetcher("/api/v2/migration");

      if (
        migration &&
        migration.status === "error" &&
        migration.id !== user.preferences.lastErroredCrmMigrationId &&
        user.role === "TeamMember"
      ) {
        SystemJS.import("clients-ui!sofe").then((clientsUi) =>
          clientsUi.renderMigrationErrorModal({ migrationId: migration.id })
        );
      }
    } else if (tenant.crm_status === "crm_hierarchy_complete") {
      const migration = await fetcher("/api/v2/migration");
      if (
        migration &&
        migration.status === "done" &&
        !user.preferences.crmMigrationCompleteModalSeen &&
        user.role === "TeamMember"
      ) {
        SystemJS.import("clients-ui!sofe").then((clientsUi) =>
          clientsUi.renderMigrationCompleteModal()
        );
      }
    }
  } catch (error) {
    handleError(error);
  }
}
